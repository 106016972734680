import * as React from "react";
import { createRoot } from "react-dom/client";

import { getMetaTagContent } from "utils/meta";

import Field from "components/Form/Field";
import Heading from "components/Heading";
import Message from "components/Message";
import Button from "components/Button";

const Login = () => {
  const email = getMetaTagContent("email", "");
  const error = getMetaTagContent("error", "");
  const csrfToken = getMetaTagContent("csrf-token", "");

  return (
    <div className="w-full max-w-[500px] mx-auto mt-[15vh] p-8 bg-white/5">
      <Heading as="h2">Login</Heading>

      {error && <Message type="error">{error}</Message>}

      <form method="post">
        <input type="hidden" name="csrf-token" value={csrfToken} />
        <Field
          type="email"
          name="email"
          title="Email"
          defaultValue={email}
          placeholder="something@something.com"
          className="mb-4"
        />
        <Field type="password" name="password" title="Password" className="mb-8" />
        <Button type="submit" accented className="w-full">
          Login
        </Button>
      </form>
    </div>
  );
};

const container = document.getElementById("app");

if (container) {
  createRoot(container).render(<Login />);
}
